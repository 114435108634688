@import '../../styles/colors';

.employees-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: $background-color;
  min-height: 100vh;

  h2 {
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .employee-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;

    .employee-item {
      background: $background-color;
      color: $primary-color;
      margin: 0.5rem 0;
      padding: 1rem;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      .employee-name {
        font-weight: bold;
      }

      .employee-position {
        font-style: italic;
      }
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}

div {
  margin-bottom: 15px;
}

label {
  display: flex;
  flex-direction: column;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

p {
  margin: 0;
}
