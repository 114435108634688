/* src/MapPage.scss */
$primary-color: #3498db;
$font-color: #2c3e50;

.jobSite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    color: $font-color;
    margin-bottom: 1.5rem;
  }
}
