$primary-color: #3498db;
$secondary-color: #2ecc71;
$font-color: #2c3e50;

.jobSite-container {
  padding: 2rem;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    color: $font-color;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .jobSite-table {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-collapse: collapse;
    background: #fff;
    color: $font-color;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    th, td {
      padding: 0.75rem 1rem;
      text-align: left;
    }

    th {
      background-color: $primary-color;
      color: #fff;
    }

    tbody tr:nth-child(odd) {
      background-color: lighten($primary-color, 40%);
    }

    tbody tr:hover {
      background-color: lighten($primary-color, 30%);
    }
  }
}
