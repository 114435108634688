/* src/ListPage.scss */
@import '../../styles/colors.scss';

.jobSite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .jobSite-info {
    background: $surface-color;
    color: $primary-color;
    margin: 0.5rem 0;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    transition: background-color 0.3s;

    input {
      text-align: center;
    }

    p {
        margin: 1rem 0;
    }

    &:hover {
      background-color: darken($surface-color, 10%);
    }
  }

}
