/* src/ListPage.scss */
@import '../../styles/colors';

.user-topline {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 50%;
}
.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .user-list {
    list-style: none;
    padding: 0;
    width: 80%;

    .user-header {
      background-color: $secondary-color !important;
      margin: 0.5rem 0 !important;
    }

    .user-item {
      background-color: $primary-color;
      display: grid;
      grid-template-columns: 3fr 1fr 2fr 1fr;
      grid-template-areas: 'user-name user-employees user-location user-view';

      color: $surface-color;
      margin: 0.25rem 0;
      padding: 0.75rem 1rem;
      border-radius: 4px;
      transition: background-color 0.3s;

      .user-name {
        display: grid;
        grid-area: user-name;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .user-employees {
        display: grid;
        grid-area: user-employees;
        font-size: 1.3rem;
      }

      .user-location {
        display: grid;
        grid-area: user-location;
        font-size: 1.3rem;
      }

      .user-view {
        display: grid;
        grid-area: user-view;
        font-size: 1.3rem;
      }

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}
