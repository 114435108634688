/* src/LoginPage.scss */
$primary-color: #3498db;
$secondary-color: #2ecc71;
$font-color: #2c3e50;

.checkin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 100vh;
  background: linear-gradient(135deg, $primary-color, $secondary-color);

  .checkin-form {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;

    .error {
      color: red;
      margin-bottom: 1rem;
    }

    h2 {
      margin-bottom: 1.5rem;
      color: $font-color;
    }

    .form-group {
      margin-bottom: 1rem;
      text-align: left;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: $font-color;
      }

      input {
        width: 100%;
        padding: 0.75rem;
        font-size: 2rem;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }

    .checkin-button {
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      background-color: $primary-color;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}
