/* src/Header.scss */
@import '../../styles/colors';

.header {
  display: grid;
  background-color: $header-color;
  grid-template-areas: 'logo menu button';
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .logo {
    grid-area: logo;
    img {
      height: 40px;
    }
  }

  .nav-menu {
    grid-area: menu;
    display: flex;
    gap: 1rem;
    align-items: center;

    .nav-item {
      text-decoration: none;
      color: $header-text-color;
      font-weight: bold;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .logout-button {
    grid-area: button;
    background-color: $secondary-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }
}
