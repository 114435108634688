/* src/ListPage.scss */
@import '../../styles/colors';

.company-topline {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 50%;
}
.company-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .company-list {
    list-style: none;
    padding: 0;
    width: 80%;

    .company-header {
      background-color: $secondary-color !important;
      margin: 0.5rem 0 !important;
    }

    .company-item, .company-item-x {
      background-color: $primary-color;
      display: grid;
      grid-template-columns: 3fr 1fr 2fr 1fr;
      grid-template-areas: 'company-name company-employees company-location company-view';

      color: $surface-color;
      margin: 0.25rem 0;
      padding: 0.75rem 1rem;
      border-radius: 4px;
      transition: background-color 0.3s;

      .company-name {
        display: block;
        grid-area: company-name;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .company-employees {
        display: block;
        grid-area: company-employees;
        font-size: 1.3rem;
      }

      .company-location {
        display: block;
        grid-area: company-location;
        font-size: 1.3rem;
      }

      .company-view {
        display: block;
        grid-area: company-view;
        font-size: 1.3rem;
      }

      .align-top {
        align-self: start;
      }

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .company-item-x {
      grid-template-columns: 1fr 2fr 2fr 2fr;
    }
  }
}

.react-file-reader {
  height: 40px;
}
